import API from "../../configs/api";
import apiMethod from "../../utility/ApiMethod";

export const getListCity = (payload) => {
  return apiMethod.get(API.GET_COMMON_GET_CITY, payload);
};

export const getListDistrict = (payload) => {
  return apiMethod.get(API.GET_COMMON_GET_DISTRICT, payload);
};

export const getShareInfoData = async (codeLanguage = "vi-VN") => {
  try {
    const { data } = await apiMethod.get(
      `/${codeLanguage}/${API.GET_SHARE_INFO}`
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getListByGroupCode = (payload) => {
  const { groupCode, ...rest } = payload;
  return apiMethod.get(`${API.OPTIONS_LIST_BY_GROUP_CODE}/${groupCode}`);
};

export const getListOptions = (payload) => {
  const { page, limit, ...rest } = payload;
  return apiMethod.post(
    `${API.OPTIONS_LIST}?page=${page}&limit=${limit}`,
    rest
  );
};

export const userLogout = async (userId) => {
  try {
    const { data } = await apiMethod.get(`${API.USER_LOGOUT}`, {
      params: { userId },
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
