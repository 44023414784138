import {
  ENV_ENVIRONMENT_BASE_URL_API,
  ENVIRONMENT_STNHD_BASE_URL_API,
} from "./environment";
export const BASE_URL_API = ENV_ENVIRONMENT_BASE_URL_API;
export const BASE_URL_API_STNHD = ENVIRONMENT_STNHD_BASE_URL_API;

export default {
  /*Common*/
  GET_COMMON_GET_CITY: "/Province",
  GET_COMMON_GET_DISTRICT: "/District",
  GET_LIST_CLASS: "/SchoolClass",
  GET_SHARE_INFO: "WebsiteSTNHD/ShareInfo",
  /*End Common*/

  /*MINDMAP*/
  CREATE_MINDMAP: "/MstMindmap/create",
  GET_LIST_MINDMAP: "/MstMindmap/list",
  GET_DETAIL_MINDMAP: "/MstMindmap/detail",
  DELETE_MINDMAP: "/MstMindmap/delete",
  POST_FILTER_MINDMAP: "/MstMindmap/listFilter",
  /*END MINDMAP*/

  /*MINDMAP INFO*/
  CREATE_MINDMAP_INFO: "/InfoMindmaps/create",
  UPDATE_MINDMAP_INFO: "/InfoMindmaps/update",
  DELETE_MINDMAP_INFO: "/InfoMindmaps/delete",
  GET_DETAIL_MINDMAP_INFO: "/InfoMindmaps/detail",
  /*END MINDMAP INFO*/

  /*MIND MAP IMAGE */
  GET_LIST_IMAGE: "/MstWarehouseImage/listFilter",
  POST_UPLOAD_IMAGE: "/MstWarehouseImage/create",
  GET_LIST_IMAGE_CATEGORY: "/MstCategoryImage/List",
  /*END MIND MAP IMAGE */
  /*MINDMAP CATEGORY*/
  POST_CREATE_MINDMAP_CATEGORY: "/MstCategoryMindmap/Create",
  GET_LIST_MINDMAP_CATEGORY: "/MstCategoryMindmap/List",
  UPDATE_CATEGORY_INFO: "/MstCategoryMindmap/Update",
  DELETE_MINDMAP_CATEGORY: "/MstCategoryMindmap/Delete",
  /*END MINDMAP CATEGORY*/
  //STNHD API
  POST_AUTH_SIGN_IN: "/SieuTriNhoHocDuong/login",
  POST_SEND_OTP: "/SieuTriNhoHocDuong/SendOTP",
  POST_SIGN_UP_TRIAL_ACCOUNT: "/SieuTriNhoHocDuong/Register",
  POST_UPDATE_TO_TRIAL_ACCOUNT: "/SieuTriNhoHocDuong/UpdateUserExperience",
  GET_TRIAL_ACCOUNT_INFO: "/SieuTriNhoHocDuong/GetUserExperience",
  POST_ACTIVE_CODE: "/SieuTriNhoHocDuong/ActiveCode",
  POST_CHECK_ACTIVE_CODE: "/SieuTriNhoHocDuong/CheckCode",
  GET_VIDEO_TUTORIAL_ACTIVE_CODE: "/SieuTriNhoHocDuong/ActiveCodeTutorial",
  //END STNHD API

  //Options
  OPTIONS_LIST_BY_GROUP_CODE: "/MstOptions/list/groupCode",
  OPTIONS_LIST: "/MstOptions/list",

  //Logout
  USER_LOGOUT: "/User/Logout",

  //GET_LIST_SETTING
  GET_LIST_SETTING: "/MstSetting/listFilter",
};
