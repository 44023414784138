import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";
import initSagas from "./Store/initSagas";

export default {
  name: "Notification",
  dir: "Notification",
  pathRoot: "notification",
  routes: [
    {
      url: "",
      component: "Page/index",
      layout: "LeftPanelLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Thông báo | VMindmap - Tâm Trí Lực",
        titleI18n: "Notification:title",
        exact: false,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  sagas: initSagas,
};
