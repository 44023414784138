import * as Actions from '../constants';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { PAGE_DEFAULT, LIMIT_DEFAULT } from '@configs/contants';

const initState = {
  levelList: [],
  teacherListByLevel: [],
  teacherListSearch: [],
  levelTabListNotificationByLevel: [],
  levelTabListNotificationSearch: [],
  pageIndexNotificationByLevel: PAGE_DEFAULT,
  pageSizeNotificationByLevel: LIMIT_DEFAULT,
  totalRowsNotificationByLevel: 0,
  totalPageNotificationByLevel: 0,
  pageIndexNotificationSearch: PAGE_DEFAULT,
  pageSizeNotificationSearch: LIMIT_DEFAULT,
  totalRowsNotificationSearch: 0,
  totalPageNotificationSearch: 0,
  loadingNotificationByLevel: false,
  loadingNotificationSearch: false,
  loadingLevelList: false,
  levelCodeNotificationByLevel: undefined,
  levelCodeNotificationSearch: undefined,
  error: '',
};

const NotificationReducers = (state = initState, action = {}) => {
  switch (action.type) {
    case Actions.GET_LEVEL_LIST_REQUEST:
      return { ...state, loadingLevelList: true };
    case Actions.GET_LEVEL_LIST_SUCCESS:
      return {
        ...state,
        levelList: action.payload,
        loadingLevelList: false,
      };
    case Actions.GET_LEVEL_LIST_FAILDED:
      return {
        ...state,
        error: action.error,
        loadingLevelList: false,
      };
    case Actions.GET_TEACHER_LIST_BY_LEVEL_REQUEST:
      return { ...state, loadingNotificationByLevel: true };
    case Actions.GET_TEACHER_LIST_BY_LEVEL_SUCCESS:
      return {
        ...state,
        levelTabListNotificationByLevel: action.payload.levelTabList,
        teacherListByLevel: action.payload.teacherList,
        loadingNotificationByLevel: false,
        pageIndexNotificationByLevel: action.payload.paging.curPage,
        pageSizeNotificationByLevel: action.payload.paging.perPage,
        totalRowsNotificationByLevel: action.payload.paging.totalRows,
        totalPageNotificationByLevel: action.payload.paging.totalPage,
        levelCodeNotificationByLevel: action.payload.levelCode,
      };
    case Actions.GET_TEACHER_LIST_BY_LEVEL_FAILDED:
      return {
        ...state,
        error: action.error,
        loadingNotificationByLevel: false,
      };
    case Actions.GET_TEACHER_LIST_SEARCH_REQUEST:
      return { ...state, loadingNotificationSearch: true };
    case Actions.GET_TEACHER_LIST_SEARCH_SUCCESS:
      return {
        ...state,
        levelTabListNotificationSearch: action.payload.levelTabList,
        teacherListSearch: action.payload.teacherList,
        loadingNotificationSearch: false,
        pageIndexNotificationSearch: action.payload.paging?.curPage,
        pageSizeNotificationSearch: action.payload.paging?.perPage,
        totalRowsNotificationSearch: action.payload.paging?.totalRows,
        totalPageNotificationSearch: action.payload.paging?.totalPage,
        levelCodeNotificationSearch: action.payload.levelCode,
      };
    case Actions.GET_TEACHER_LIST_SEARCH_FAILDED:
      return {
        ...state,
        error: action.error,
        loadingNotificationSearch: false,
      };

    default: {
      return state;
    }
  }
};

const persistConfig = {
  key: 'Notification',
  storage,
  blacklist: ['loading', 'error'],
};

export default persistReducer(persistConfig, NotificationReducers);
