import API from "@Modules/Home/configs/api";
import apiMethod from "@utility/ApiMethod";

export const getHomeData = async (codeLanguage = "vi-VN") => {
  try {
    const { data } = await apiMethod.get(`/${codeLanguage}/${API.GET_BANNER}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getFreeLesson = async () => {
  try {
    const { data } = await apiMethod.post(
      `${API.GET_LESSON_CLIENT}?page=1&limit=200`,
      {
        freeLesson: true,
      }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getSilderBanner = async () => {
  try {
    const { data } = await apiMethod.post(`${API.GET_UPLOAD_IMAGE}`, {
      page: 1,
      limit: 50,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const registerJoin = async (payload) => {
  try {
    const { data } = await apiMethod.post(`${API.REGISTER_JOIN}`, payload);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getHonorWeek = async (payload) => {
  try {
    const { page, limit, ...rest } = payload;
    const { data } = await apiMethod.post(
      `${API.HONOR_WEEK}?limit=${limit}&page=${page}`,
      rest
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
