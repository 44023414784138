import API from "@Modules/Profile/configs/api";
import apiMethod from "@utility/ApiMethod";

export const getUserProfile = async (payload) => {
  try {
    const { data } = await apiMethod.post(API.GET_PROFILE, payload);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateProfile = async (payload) => {
  try {
    const { data } = await apiMethod.put(`${API.UPĐATE_PROFILE}`, payload);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getTopicClient = async (payload) => {
  try {
    const { page, limit, ...rest } = payload;
    const { data } = await apiMethod.post(
      `${API.GET_TOPIC_CLIENT}?page=${page}&limit=${limit}`,
      rest
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getLessonClient = async (payload) => {
  try {
    const { page, limit, ...rest } = payload;
    const { data } = await apiMethod.post(
      `${API.GET_LESSON_CLIENT}?page=${page}&limit=${limit}`,
      rest
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getTestClient = async (payload) => {
  try {
    const { data } = await apiMethod.post(`${API.GET_TEST_CLIENT}`, payload);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getGiftCardUser = async (payload) => {
  try {
    const { data } = await apiMethod.get(`${API.GET_GIFT_CARD}`, {
      params: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getListInviteByUserId = async (userId) => {
  try {
    const { data } = await apiMethod.get(
      `${API.LIST_INVITE_BY_USERID}/${userId}`
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getTopUserWeek = async (payload) => {
  try {
    const { data } = await apiMethod.post(`${API.GET_USER_WEEK}`, payload);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

// method: post | exchange gift
export const exchangeGift = async (payload) => {
  try {
    const { data } = await apiMethod.post(`${API.EXCHANGE_GIFT}`, payload);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getDetailTopic = async (id) => {
  try {
    const { data } = await apiMethod.get(`${API.GET_DETAIL_TOPIC}/${id}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
