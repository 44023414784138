import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import * as Actions from "./constants";
import { logout } from "../../helpers";
import { toast } from "react-toastify";

const initState = {
  accessToken: null,
  userData: {},
  loading: false,
  loginSuccess: false,
  error: undefined,
};

const AuthReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case Actions.CALL_SIGN_IN_METHOD:
      return { ...state, loading: true, loginSuccess: false };
    case Actions.SIGN_IN_SUCCESS:
      const { token } = action.payload;
      const accessToken = `Bearer ${token}`;
      return {
        ...state,
        accessToken,
        userData: { ...action.payload },
        loading: false,
        error: undefined,
        loginSuccess: true,
      };
    case Actions.SIGN_IN_ERROR:
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
        loginSuccess: false,
        accessToken: null,
        userData: {},
      };
    case Actions.CALL_LOGOUT_METHOD_SUCCESS:
      logout();
      return {
        ...state,
        loading: false,
        error: undefined,
        loginSuccess: false,
        accessToken: null,
        userData: {},
      };
    case Actions.SIGN_IN_RESET:
      return {
        ...state,
        loginSuccess: false,
        accessToken: null,
        loading: false,
        error: undefined,
      };

    ///register
    case Actions.CALL_SIGN_UP_METHOD:
      return { ...state, loading: true };

    case Actions.SIGN_UP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        loginSuccess: true,
        accessToken,
        userData: { ...action.payload },
      };
    case Actions.SIGN_UP_ERROR:
      return {
        ...state,
        loading: false,
        error,
        loginSuccess: false,
        accessToken: null,
        userData: {},
      };
    case Actions.SIGN_UP_RESET:
      return {
        ...state,
        loading: false,
        error: "",
      };

    default:
      return state;
  }
};

const persistConfig = {
  key: "Auth",
  storage,
};

export default persistReducer(persistConfig, AuthReducer);
