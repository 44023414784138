export default {
  GET_PROFILE: "/User/GetProfile",
  UPĐATE_PROFILE: "/User/update",

  GET_LESSON_CLIENT: "/MstLessonClient/listfilter",

  GET_TEST_CLIENT: "/MstTestClient/listfilter",

  GET_GIFT_CARD: "/InfoGiftcardUser/list-client",

  GET_USER_WEEK: "/MstHonorWeek/listFilter",
  LIST_INVITE_BY_USERID: "/User/MyInvitedUser",
  EXCHANGE_GIFT: "/InfoGiftcardUser/exchange-gift",

  GET_TOPIC_CLIENT: "/TopicsClient/ListFilter",

  GET_DETAIL_TOPIC: "/MstCategoryTopics/Detail",
};
