export const setToken = (token) => {
  localStorage.setItem("token", token);
};

export const setAccessToken = (token) => {
  localStorage.setItem("accessToken", token);
};

export const setUserInfo = (user) => {
  localStorage.setItem("user_info", JSON.stringify(user));
};

export const logout = () => {
  localStorage.clear();
  window.location.href = "/";
  return true;
};
