import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
// import initReducer from './Store/initReducer';

export default {
  name: "Discover",
  dir: "Discover",
  pathRoot: "discover",
  routes: [
    {
      url: "",
      component: "Page/index",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "VMindmap - Tâm Trí Lực",
        titleI18n: "Discover:tabTitle",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: "Any",
  // redux: initReducer,
};
