import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import * as Actions from "./constants";

const initState = {
  loading: false,
  loginSuccess: false,
  error: "",
};

const ProfileReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case Actions.UPADTE_USER_PROFILE:
      return { ...state, loading: true };
    case Actions.UPADTE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case Actions.UPADTE_USER_PROFILE_ERROR:
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };

    default:
      return state;
  }
};

const persistConfig = {
  key: "Profile",
  storage,
};

export default persistReducer(persistConfig, ProfileReducer);
