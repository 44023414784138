import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initSagas from "./Store/initSagas";
import initReducer from "./Store/initReducer";

export default {
  name: "Authenticate",
  dir: "Authenticate",
  pathRoot: "",
  routes: [
    {
      url: "register",
      component: "Page/Register",
      layout: "VerticalLayout",
      meta: {
        authRoute: false,
      },
      props: {
        title: "Đăng ký | vMindmap Tâm Trí Lực",
        titleI18n: "Auth:registerTab",
        exact: true,
      },
    },
    {
      url: "login",
      component: "Page",
      layout: "VerticalLayout",
      meta: {
        authRoute: false,
      },
      props: {
        title: "Đăng nhập | vMindmap Tâm Trí Lực",
        titleI18n: "Auth:title",
        exact: true,
      },
    },
    {
      url: "forgot",
      component: "Page/Forgot.js",
      layout: "VerticalLayout",
      meta: {
        authRoute: false,
      },
      props: {
        title: "Quên mật khẩu | vMindmap Tâm Trí Lực",
        titleI18n: "Auth:forgotTitle",
        exact: true,
      }
    }
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: false,
  sagas: initSagas,
  redux: initReducer,
};
