import profileReducer from "./Profile/reducer";
import {
  area_of_interest_select,
  skill_want_select,
} from "@Modules/Profile/configs/constants";
import list_area_of_interest_select from "./reducers/areaInterest";
import list_skill_want_select from "./reducers/skillWant";

export default {
  profile: profileReducer,
  [area_of_interest_select]: list_area_of_interest_select,
  [skill_want_select]: list_skill_want_select,
};
