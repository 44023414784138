let ENV_ENVIRONMENT_BASE_URL_API_CONFIG =
  "https://apievmm.5phutthuocbai.com/api";

let PIXABAY_API_URL = "https://pixabay.com/api/";
let PIXABAY_API_KEY = "25191544-c3d061a239a902c0c428cc1ad";
let VERSION_CODE = "1.0.0";

let ENV_ENVIRONMENT_awsKeys = {
  accessKeyID: "7f07a8ad279a6e89870f",
  secretKeyID: "rFbo8DQ8zQ5lNvUKWlRvNh8LBuyVVZgFxVlTBANd",
  endpoint: "https://s3-sgn09.fptcloud.com",
  bucket: "mindmap",
};

let PrefixChanelRoom = "DEV";

switch (process.env.NODE_ENV) {
  case "testing":
    ENV_ENVIRONMENT_BASE_URL_API_CONFIG =
      "https://apievmm.5phutthuocbai.com/api";
    ENV_ENVIRONMENT_awsKeys = {
      accessKeyID: "7f07a8ad279a6e89870f",
      secretKeyID: "rFbo8DQ8zQ5lNvUKWlRvNh8LBuyVVZgFxVlTBANd",
      endpoint: "https://s3-sgn09.fptcloud.com",
      bucket: "mindmap",
    };
    PrefixChanelRoom = "TEST";
    break;
  case "production":
    ENV_ENVIRONMENT_BASE_URL_API_CONFIG =
      "https://apievmm.5phutthuocbai.com/api";

    ENV_ENVIRONMENT_awsKeys = {
      accessKeyID: "7f07a8ad279a6e89870f",
      secretKeyID: "rFbo8DQ8zQ5lNvUKWlRvNh8LBuyVVZgFxVlTBANd",
      endpoint: "https://s3-sgn09.fptcloud.com",
      bucket: "mindmap",
    };

    PrefixChanelRoom = "PRODUCTION";
    break;
  default:
    ENV_ENVIRONMENT_BASE_URL_API_CONFIG =
      "https://apievmm.5phutthuocbai.com/api";
    ENV_ENVIRONMENT_awsKeys = {
      accessKeyID: "7f07a8ad279a6e89870f",
      secretKeyID: "rFbo8DQ8zQ5lNvUKWlRvNh8LBuyVVZgFxVlTBANd",
      endpoint: "https://s3-sgn09.fptcloud.com",
      bucket: "mindmap",
    };

    PrefixChanelRoom = "DEV";
    break;
}

export const ENV_ENVIRONMENT_BASE_URL_API = ENV_ENVIRONMENT_BASE_URL_API_CONFIG;
export const ENV_ENVIRONMENT_PIXABAY_API = PIXABAY_API_URL;
export const ENV_ENVIRONMENT_PIXABAY_API_KEY = PIXABAY_API_KEY;
export const ENVIRONMENT_awsKeys = ENV_ENVIRONMENT_awsKeys;
export const ENV_ENVIRONMENT_VERSION_CODE = VERSION_CODE;
export const ENVIRONMENT_STNHD_BASE_URL_API = "https://apidev.stnhd.com/api";
export const PREVENT_LOGIN_PRODUCTION = false;
export const ENV_ENVIRONMENT_SOCKET_URL =
  "https://ttl-vmindmap-socket.sunshine.software/";
export const ENV_PREFIX_SOCKET = PrefixChanelRoom;
