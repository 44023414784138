import API from "@Modules/Authenticate/configs/api";
import apiMethod from "@utility/ApiMethod";

export const signIn = async (payload) => {
  try {
    const { data } = await apiMethod.post(API.POST_AUTH_SIGN_IN, payload);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
  // return apiMethod.post(API.POST_AUTH_SIGN_IN, payload);
};

export const registerUser = (payload) => {
  return apiMethod.post(API.AUTHENTICATE_REGISTER, payload);
};

export const checkInviteCode = (code) => {
  return apiMethod.get(`${API.CHECK_INVITE_CODE}/${code}`);
};

export const loginSocial = async (payload) => {
  try {
    const { data } = await apiMethod.post(API.LOGIN_SOCIAL, payload);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
