import { put, call, takeEvery, select } from "redux-saga/effects";
import * as Actions from "./constants";
import { updateProfile } from "./service";
import { RETCODE_SUCCESS, SUCCESS } from "@configs/contants";
import { actions as ActionsUser } from "../../../../store/user/reducer";

/**
 * Fetch data saga
 * @returns {IterableIterator<*>}
 */
function* fetchUpdateProfileSaga({ payload }) {
  try {
    const res = yield call(updateProfile, payload);
    const { data } = res;
    if (res.status === SUCCESS && data.retCode === RETCODE_SUCCESS) {
      yield put(ActionsUser.setInfoData(res.data.data));
      yield put({
        type: Actions.UPADTE_USER_PROFILE_SUCCESS,
        payload: res.data.data,
      });
    } else {
      yield put({
        type: Actions.UPADTE_USER_PROFILE_ERROR,
        error: data.retText,
      });
    }
  } catch (e) {
    yield put({ type: Actions.UPADTE_USER_PROFILE_ERROR, error: e });
  }
}

export default function* profileSaga() {
  yield takeEvery(Actions.UPADTE_USER_PROFILE, fetchUpdateProfileSaga);
}
