import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";

import listReducers from "./Store/initReducer";
import listSagas from "./Store/initSagas";

export default {
  name: "Profile",
  dir: "Profile",
  pathRoot: "profile",
  routes: [
    {
      url: "",
      component: "Page",
      layout: "VerticalLayout",
      meta: {
        authRoute: false,
      },
      props: {
        title: "Trang cá nhân | Vmindmap",
        titleI18n: "Profile:titleTab",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  sagas: [...listSagas],
  redux: { ...listReducers },
};
