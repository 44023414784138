import * as Actions from '../constants';
import {
  getLevelList,
  getNotificationListByLevel,
  getNotificationListBySearch,
} from '../service';
import { put, call, takeEvery, select } from 'redux-saga/effects';
import { getCodeLanguage } from '@store/common/selectors';

function* getLevelListSaga({ payload }) {
  try {
    const codeLanguage = yield select(getCodeLanguage);
    const res = yield call(getLevelList, { codeLanguage, payload });
    const { data } = res;
    if (res.retCode === 0) {
      yield put({
        type: Actions.GET_LEVEL_LIST_SUCCESS,
        payload: { ...data, levelCode: payload?.levelCode },
      });
    } else {
      yield put({ type: Actions.GET_LEVEL_LIST_FAILDED, error: res.retText });
    }
  } catch (e) {
    yield put({ type: Actions.GET_LEVEL_LIST_FAILDED, error: e });
  }
}

function* getNotificationListByLevelSaga({ payload }) {
  try {
    const codeLanguage = yield select(getCodeLanguage);
    const res = yield call(getNotificationListByLevel, { codeLanguage, payload });
    const { data } = res;
    if (res.retCode === 0) {
      yield put({
        type: Actions.GET_TEACHER_LIST_BY_LEVEL_SUCCESS,
        payload: { ...data, levelCode: payload?.levelCode },
      });
    } else {
      yield put({
        type: Actions.GET_TEACHER_LIST_BY_LEVEL_FAILDED,
        error: res.retText,
      });
    }
  } catch (e) {
    yield put({ type: Actions.GET_TEACHER_LIST_BY_LEVEL_FAILDED, error: e });
  }
}

function* getNotificationListSearchSaga({ payload }) {
  try {
    const codeLanguage = yield select(getCodeLanguage);
    const res = yield call(getNotificationListBySearch, { codeLanguage, payload });
    const { data } = res;
    if (res.retCode === 0) {
      yield put({
        type: Actions.GET_TEACHER_LIST_SEARCH_SUCCESS,
        payload: { ...data, levelCode: payload?.levelCode },
      });
    } else {
      yield put({
        type: Actions.GET_TEACHER_LIST_SEARCH_FAILDED,
        error: res.retText,
      });
    }
  } catch (e) {
    yield put({ type: Actions.GET_TEACHER_LIST_SEARCH_FAILDED, error: e });
  }
}

export default function* notificationSaga() {
  yield takeEvery(Actions.GET_LEVEL_LIST_REQUEST, getLevelListSaga);
  yield takeEvery(
    Actions.GET_TEACHER_LIST_BY_LEVEL_REQUEST,
    getNotificationListByLevelSaga
  );
  yield takeEvery(
    Actions.GET_TEACHER_LIST_SEARCH_REQUEST,
    getNotificationListSearchSaga
  );
}
