export default [
  //Dynamic module
  { key: "Profile" },
  { key: "Lesson" },
  { key: "LessonDetail" },
  { key: "Discover" },
  { key: "DetailLesson" },
  { key: "Introduce" },

  //Static module
  { key: "ComingSoon" },
  { key: "Authenticate" },
  { key: "Home" },
];
